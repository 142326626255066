body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  min-height: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
p {
  margin: 0;
}

@font-face {
  font-family: 'DIN-Regular';
  src: url('fonts/DIN-Regular.eot'); /* IE9 Compat Modes */
  src: url('fonts/DIN-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('fonts/DIN-Regular.woff') format('woff'),
    /* Pretty Modern Browsers */ url('fonts/DIN-Regular.ttf') format('truetype');
}

* {
  font-family: 'DIN-Regular';
}

.underline {
  text-decoration: underline;
}

h1 {
  font-family: 'Playfair Display', serif;
  font-size: 80px;
  color: white;
  font-weight: normal;
  line-height: 1;
}

h2 {
  font-size: 50px;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  color: #60c5db;
  position: relative;
  padding-left: 76px;
}

h2::before {
  content: ' ';
  position: absolute;
  background: #60c5db;
  width: 50px;
  height: 2px;
  margin-right: 26px;
  top: 50%;
  left: 0;
}
h3 {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  color: white;
}

h4 {
  font-family: 'Playfair Display', serif;
  color: #012b65;
  font-size: 30px;
  letter-spacing: 1px;
  font-weight: bold;
}

.paragraph-mentions::before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 4px;
  height: 4.4rem;
  background: #60c5db;
}
.paragraphBefore {
  padding-left: 24px;
  position: relative;
  color: white;
  line-height: 1.2em;
}
.paragraphBefore::before {
  content: ' ';
  position: absolute;
  width: 2px;
  background: white;
  height: 100%;
  left: 0;
}
@media all and (max-width: 1270px) {
  h1 {
    font-size: 60px;
  }
  h3 {
    font-size: 14px;
  }
}

@media all and (max-width: 580px) {
  h2 {
    font-size: 40px;
  }
}
